export const routes = [
    {
        path: '',
        component: () => import('../views/homePage/HomePage'),
        meta: {title: '首页'}
    },
    {
        path: 'SearchResult',
        component: () => import('../views/homePage/SearchResult'),
        meta: {title: '站内搜索'}
    },
    {
        path: 'AboutUs/:id?',
        component: () => import('../views/aboutUs/AboutUs'),
        meta: {title: '关于我们'}
    },
    {
        path: 'AboutUsGridDetail/:id?',
        component: () => import('../views/aboutUs/AboutUsGridDetail'),
        meta: {title: '网格详情'}
    },
    {
        path: 'NewsInformation/:id?',
        component: () => import('../views/newsInformation/NewsInformation'),
        meta: {title: '新闻资讯'}
    },
    {
        path: 'NewsInformationDetail/:id',
        component: () => import('../views/newsInformation/NewsInformationDetail'),
        meta: {title: '新闻详情'}
    },
    {
        path: 'BookCenter/:id?',
        component: () => import('../views/bookCenter/BookCenter'),
        meta: {title: '图书中心'}
    },
    {
        path: 'BookCenterDetail/:id',
        component: () => import('../views/bookCenter/BookCenterDetail'),
        meta: {title: '图书详情'}
    },
    {
        path: 'ServiceCentre/:id?',
        component: () => import('../views/serviceCentre/ServiceCenter'),
        meta: {title: '服务中心'}
    },
    {
        path: 'ServiceCenterDetail/:id',
        component: () => import('../views/serviceCentre/ServiceCenterDetail'),
        meta: {title: '服务详情'}
    },
    {
        path: 'OnlineCustomizedPrograms/:id?',
        component: () => import('../views/serviceCentre/OnlineCustomizedPrograms'),
        meta: {title: '在线定制节目'}
    },
    {
        path: 'ContactUs/:id?',
        component: () => import('../views/contactUs/ContactUs'),
        meta: {title: '联系我们'}
    },
    {
        path: 'TalentRecruitment/:id?',
        component: () => import('../views/contactUs/TalentRecruitment'),
        meta: {title: '人才招聘'}
    },
    {
        path: 'layout',
        component: () => import('../components/SideBar'),
        children: [
            {
                path: 'AboutUsGrid/:id?',
                component: () => import('../views/aboutUs/AboutUsGrid'),
                meta: {title: '关于我们网格'}
            },
            {
                path: 'CoursewareDownload/:id?',
                component: () => import('../views/serviceCentre/CoursewareDownload'),
                meta: {title: '课件下载'}
            },
            {
                path: 'ProgramDownload/:id?',
                component: () => import('../views/serviceCentre/ProgramDownload.vue'),
                meta: {title: '节目下载'}
            },
            {
                path: 'SampleApplication/:id?',
                component: () => import('../views/serviceCentre/SampleApplication'),
                meta: {title: '样书申请'}
            },
            {
                path: 'AboutUsDetail/:id?',
                component: () => import('../views/aboutUs/AboutUsDetail'),
                meta: {title: '关于我们详情'}
            },
            {
                path: 'WelcomeToContribute/:id?',
                component: () => import('../views/contactUs/WelcomeToContribute'),
                meta: {title: '欢迎投稿'}
            },
        ]
    }
]