import {router} from "@/router";

export default {
    pic: 'https://t7.baidu.com/it/u=1956604245,3662848045&fm=193&f=GIF',
    logo: require('@/assets/img/logo.png'),
    logo2: require('@/assets/img/logo2.png'),
    homeBg01: require('@/assets/img/bg01@2x.png'),
    newsBanner: require('@/assets/img/newsBanner@2x.png'),
    downloadQrCode: require('@/assets/img/downloadQrCode.png'),
    copyRightIco: require('@/assets/img/copyRightIco.jpg'),
    toPath(path) {
        router.push(path).then()
    },
    openPath(path) {
        window.open(path, '_blank')
    }
}