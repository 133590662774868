import Vue from 'vue'
import App from './App.vue'
import './assets/css/reset.css'
import './assets/css/common.css'


//路由
import VueRouter from 'vue-router'
import {router} from "@/router";
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)


//elementUI
import ElementUI from 'element-ui';
import '@/assets/css/theme.scss';
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(ElementUI, {size: 'small'});

//公共变量
import global from "@/assets/utils/global";
Vue.prototype.$global = global

//day.js
import dayjs from "dayjs";
Vue.prototype.$dayjs = dayjs

Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
