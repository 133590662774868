import VueRouter from "vue-router";
import {routes} from "@/router/routes";
import {getSiteName} from "@/util/dataStorage";

const allRoute = [
    {
        path: '/',
        component: () => import('../components/pageLayout/PageLayout'),
        children: routes
    },
    {
        path: '*',
        component: () => import('../components/NotFound')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes: allRoute
})

// router.beforeEach((to, from, next) => {
    // if (to.meta.title) {document.title = to.meta.title}
    // next();
// });
router.afterEach(() => {
    //每次路由跳转，设置默认的标题
    document.title = getSiteName();
    window.scrollTo(0,0)
})
// eslint-disable-next-line no-unused-vars
// router.beforeEach((to, from, next) => {
//     console.log("to",to.path);
//
// })

export {
    router
}
