let localStorage = require('local-storage')

/**
 * 存储网站名称
 * @returns {*}
 */
export function getSiteName() {
    return localStorage.get("document_title");
}

/**
 * 获取网站名称
 * @param siteName
 * @returns {*}
 */
export function setSiteName(siteName) {
    localStorage.set("document_title", siteName)
    return siteName;
}

